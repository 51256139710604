<template>
  <div>
    <v-form ref="addressesForm">
      <v-row>
        <v-col
          v-for="(address, index) in sendData.addresses"
          :key="index"
          :value="address"
          md="6"
          sm="12"
          lg="4"
          cols="4"
        >
          <v-card
            flat
            class="mt-5 ghd-border-black"
          >
            <v-row
              class="mt-3 p-2"
            >
              <v-col
                cols="10"
                offset="1"
              >
                <v-text-field
                  v-model="sendData.addresses[index].nome"
                  :label="userData.role.toUpperCase() === 'SALONE' ? 'Ragione sociale' : 'Nome' "
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="sendData.addresses[index].nome = sendData.addresses[index].nome.toUpperCase()"
                />

                <v-text-field
                  v-if="userData.role.toUpperCase() === 'PRIVATO'"
                  v-model="sendData.addresses[index].cognome"
                  label="Cognome"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="sendData.addresses[index].cognome = sendData.addresses[index].cognome.toUpperCase()"
                />

                <v-text-field
                  v-model="sendData.addresses[index].via"
                  label="Indirizzo"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="sendData.addresses[index].via = sendData.addresses[index].via.toUpperCase()"
                />

                <v-text-field
                  v-model="sendData.addresses[index].civico"
                  label="Civico"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="sendData.addresses[index].civico = sendData.addresses[index].civico.toUpperCase()"
                />

                <v-text-field
                  v-model="sendData.addresses[index].cap"
                  label="Cap"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="sendData.addresses[index].cap = sendData.addresses[index].cap.toUpperCase()"
                />

                <v-select
                  v-model="sendData.addresses[index].provincia"
                  :items="province"
                  item-text="label"
                  item-value="value"
                  hide-details="auto"
                  label="Provincia"
                  outlined
                  dense
                  :rules="[validators.required]"
                  @keyup="sendData.addresses[index].provincia = sendData.addresses[index].provincia.toUpperCase()"
                />

                <v-select
                  v-model="sendData.addresses[index].citta"
                  :items="filterCityByProvince(address.provincia)"
                  item-text="label"
                  hide-details="auto"
                  label="Comune"
                  class="mt-6 pb-6"
                  outlined
                  dense
                  :rules="[validators.required]"
                />

                <v-text-field
                  v-model="sendData.addresses[index].telefono"
                  label="Telefono"
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Telefono"
                  :rules="[validators.required]"
                  class="pb-6"
                  @keyup="sendData.addresses[index].telefono = sendData.addresses[index].telefono.toUpperCase()"
                />

                <v-text-field
                  v-model="sendData.addresses[index].note"
                  label="Altre informazioni di consegna (Opzionale)"
                  outlined
                  dense
                  hide-details="auto"
                  placeholder="Altre informazioni di consegna (Opzionale)"
                  @keyup="sendData.addresses[index].note = sendData.addresses[index].note.toUpperCase()"
                />

                <v-checkbox
                  v-model="sendData.addresses[index].predefinito"
                  :readonly="sendData.addresses[index].predefinito"
                  :value="1"
                  hide-details
                  label="Predefinito"
                  class="pb-6"
                  :true-value="1"
                  :false-value="0"
                  @click="setPredefinito(index)"
                />

                <v-btn
                  v-if="isPrivato()"
                  color="primary"
                  outlined
                  block
                  class="mb-4"
                  @click="onSubmit()"
                >
                  SALVA
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          v-if="false"
          md="4"
          sm="6"
          cols="12"
        >
          <v-card
            flat
            class="mt-5 ghd-border-black text-center"
          >
            <span
              class="text-large text-black ghd-ITCAvant text-uppercase cursor-pointer"
              @click="addNewAddress()"
            >
              Nuovo indirizzo
            </span>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { getUserData } from '@core/utils'
import { required } from '@core/utils/validation'
import userService from '@/services/userService'

export default {
  name: 'UserAddresses',
  setup() {
    const addressesForm = ref(null)

    const {
      province,
      comuni,
      generateProvincia,
      generateComune,
      filterCityByProvince,
      updateUser,
    } = userService()

    generateProvincia()
    generateComune()

    const userData = computed(() => getUserData())

    const blankSendData = userData.value

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))

    const addNewAddress = () => {
      const newAddress = {}

      // recupero le chiavi di un address gia esistente cosi da replicarlo
      const defaultAddress = Object.keys(sendData.value.addresses[0])
      defaultAddress.forEach(item => {
        newAddress[item] = item === 'predefinito' ? false : ''
      })

      sendData.value.addresses.unshift(newAddress)
    }

    const setPredefinito = index => {
      sendData.value.addresses.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.predefinito = false
      })

      sendData.value.addresses[index].predefinito = true
    }

    const onSubmit = () => {
      const isAddressesFormValidate = addressesForm.value.validate()

      if (!isAddressesFormValidate) return

      updateUser(sendData.value)
    }
    const isPrivato = () => blankSendData.role.toUpperCase() === 'PRIVATO'

    return {
      addressesForm,

      comuni,
      userData,
      province,
      sendData,
      isPrivato,
      updateUser,
      addNewAddress,
      filterCityByProvince,
      setPredefinito,
      onSubmit,
      validators: {
        required,
      },
    }
  },
}
</script>

<style scoped>

</style>
